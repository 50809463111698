import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { BaseValidatorDirective } from '../components/shared/base-form/base-form-validators/base-validator.directive';
import { BehaviorSubject, Observable, Subscription, merge, of } from 'rxjs';
import { PseudoValidator } from '@util/classes/pseudo-validator.class';
import { StandaloneValidator } from '@util/classes/standalone-validator.class';


interface UtilOmnipresentInvalidDirectiveObject {
  validator: BaseValidatorDirective | PseudoValidator | StandaloneValidator;
  errors: ValidationErrors;
}

export interface UtilOmnipresentFormGroupError {
  invalidValidators: UtilOmnipresentInvalidDirectiveObject[];
  combinedErrors: string[];
  errorMessage: string;
  invalid: boolean;
}


@Injectable({ providedIn: 'root' })
export class UtilOmnipresentFormGroupService {

  private nameToValidatorsMap: Map<string, (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[]> = new Map();
  private nameToBehaviorSubjectMap: Map<string, BehaviorSubject<UtilOmnipresentFormGroupError>> = new Map();
  private nameToInternalSubscription: Map<string, Subscription> = new Map();


  registerValidator(name: string, validator: BaseValidatorDirective | PseudoValidator | StandaloneValidator) {
    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);

    // is name in map
    if (arr) {
      // check if directive is already in the array associated with the name
      if (!arr.includes(validator)) {
        arr.push(validator);
        this.updateCombinedValueChangeOfDirectiveInSubject(name);
      }

    } else {
      // if name is not in map, then create name with an array of the directive
      this.nameToValidatorsMap.set(name, [validator]);
      this.updateCombinedValueChangeOfDirectiveInSubject(name);
    }
  }

  unregisterValidator(name: string, validator: BaseValidatorDirective | PseudoValidator | StandaloneValidator) {
    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);
    const i = this.getIndexOfValidator(name, validator);
    if (i >= 0) {
      arr.splice(i, 1);
      this.updateCombinedValueChangeOfDirectiveInSubject(name);
    }
  }


  flushValidators(name: string): number {
    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);
    if (arr) {
      this.nameToValidatorsMap.set(name, []);
      this.updateCombinedValueChangeOfDirectiveInSubject(name);
    }
    return arr?.length;
  }

  hasValidator(name: string, validator: BaseValidatorDirective | PseudoValidator | StandaloneValidator) {
    const i = this.getIndexOfValidator(name, validator);
    return i >= 0;
  }

  private getIndexOfValidator(name: string, validator: BaseValidatorDirective | PseudoValidator | StandaloneValidator): number {
    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);
    // is name in map
    if (arr) {
      const i = arr.indexOf(validator);
      return i;
    } else {
      return -1;
    }
  }

  getFormGroupError$(name: string): Observable<UtilOmnipresentFormGroupError> {
    this.checkBehaviorSubjectExistence(name);
    return this.nameToBehaviorSubjectMap.get(name).asObservable();
  }

  updateValueAndValidityOfFormGroup(name: string) {
    const arr = this.nameToValidatorsMap.get(name);
    if (arr) {
      arr.forEach(directive => directive.host?.formControl?.updateValueAndValidity());
      this.testFormGroup(name);
    }
  }

  private updateCombinedValueChangeOfDirectiveInSubject(name: string) {

    let sub: Subscription;
    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);

    sub = this.nameToInternalSubscription.get(name);
    sub?.unsubscribe();

    this.checkBehaviorSubjectExistence(name);

    if (arr?.length) {

      const obsArr = arr.filter(dir => !!dir.host?.formControl?.valueChanges).map(dir => dir.host?.formControl?.valueChanges);

      sub = merge(...obsArr).subscribe(val => {
        this.testFormGroup(name);
      });

      this.testFormGroup(name);
      this.nameToInternalSubscription.set(name, sub);

    } else {
      // check if behaviorsubject of this name has any errors as its current value
      if (this.nameToBehaviorSubjectMap.get(name)?.value?.combinedErrors?.length) {
        this.nameToBehaviorSubjectMap.get(name).next({
          combinedErrors: [],
          invalidValidators: [],
          errorMessage: null,
          invalid: false
        });
      }
    }

  }

  private checkBehaviorSubjectExistence(name: string) {
    if (!this.nameToBehaviorSubjectMap.has(name)) {
      this.nameToBehaviorSubjectMap.set(name, new BehaviorSubject(null));
    }
  }

  private testFormGroup(name: string) {

    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);

    const err: UtilOmnipresentFormGroupError = {
      combinedErrors: [],
      invalidValidators: [],
      errorMessage: null,
      invalid: false
    };

    const combinedRawErrors: ValidationErrors = {};

    arr.forEach(validator => {

      const localRawErrors = validator?.host?.formControl?.errors;

      if (Object.keys(localRawErrors || {}).length) {
        err.invalidValidators.push({
          validator,
          errors: localRawErrors
        });

        Object.assign(combinedRawErrors, localRawErrors);
      }
    });

    err.combinedErrors = Object.keys(combinedRawErrors).map<string>(key => combinedRawErrors[key]).filter(_ => typeof _ === 'string');
    err.errorMessage = err.combinedErrors.join(', ');
    err.invalid = this.isFormGroupInvalid(name); // asks the actual FormControl-Object -> more accurate

    this.nameToBehaviorSubjectMap.get(name)?.next(err);
  }

  private isFormGroupInvalid(name: string): boolean {
    const arr: (BaseValidatorDirective | PseudoValidator | StandaloneValidator)[] = this.nameToValidatorsMap.get(name);
    return arr.map(dir => dir?.host?.formControl?.invalid).includes(true);
  }

}


export class UtilOmnipresentFormGroupTestingService {

  registerValidator(name: string, directive: BaseValidatorDirective) {}

  unregisterValidator(name: string, directive: BaseValidatorDirective) {}

  hasValidator(name: string, validator: BaseValidatorDirective | PseudoValidator) {
    return false;
  }

  getFormGroupError$(name: string): Observable<UtilOmnipresentFormGroupError> {
    return of(null);
  }

  updateValueAndValidityOfFormGroup(name: string) {}

}
